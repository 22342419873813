
.lead .hero {
    background-image: url('../../images/van.jpg');
    background-position-x: 50%;
    background-position-y: 35%;
    background-size: cover;
    height: 500px;
    pointer-events: auto;
    position: fixed;
    width: 100%;
    z-index: -1;
}
